import { MAKER_ID } from '@/newcar/util/const/maker';

export type FuelDetail = {
  modelId: string,
  modelName: string,
  consumption: number,
}

export type FuelRankingListItem = {
  makerId: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
  fuelDetails: FuelDetail[],
}

export const FUEL_LIST: FuelRankingListItem[] = [
  {
    makerId: MAKER_ID.TOYOTA,
    fuelDetails: [
      {
        modelId: '10192',
        modelName: 'ピクシスエポック',
        consumption: 35.2,
      },
      {
        modelId: '10166',
        modelName: 'カローラツーリング',
        consumption: 35,
      },
      {
        modelId: '10168',
        modelName: 'ライズ',
        consumption: 34.8,
      },
      {
        modelId: '10079',
        modelName: 'カローラスポーツ',
        consumption: 34.2,
      },
      {
        modelId: '10108',
        modelName: 'カローラアクシオ',
        consumption: 32.2,
      },
      {
        modelId: '10084',
        modelName: 'カローラフィールダー',
        consumption: 32.2,
      },
      {
        modelId: '10178',
        modelName: 'ヤリス　クロス',
        consumption: 31.3,
      },
      {
        modelId: '10003',
        modelName: 'Ｃ－ＨＲ',
        consumption: 30.4,
      },
      {
        modelId: '10007',
        modelName: 'シエンタ',
        consumption: 28.8,
      },
      {
        modelId: '10038',
        modelName: 'パッソ',
        consumption: 28,
      },
    ],
  },
  {
    makerId: MAKER_ID.NISSAN,
    fuelDetails: [
      {
        modelId: '10002',
        modelName: 'ノート',
        consumption: 38.2,
      },
      {
        modelId: '10196',
        modelName: 'オーラ',
        consumption: 33,
      },
      {
        modelId: '10043',
        modelName: 'デイズ',
        consumption: 29.4,
      },
      {
        modelId: '10174',
        modelName: 'ルークス',
        consumption: 27.2,
      },
      {
        modelId: '10006',
        modelName: 'セレナ',
        consumption: 26,
      },
      {
        modelId: '10020',
        modelName: 'エクストレイル',
        consumption: 18.2,
      },
      {
        modelId: '10144',
        modelName: 'クリッパーリオ',
        consumption: 16.4,
      },
      {
        modelId: '10145',
        modelName: 'スカイライン',
        consumption: 14.4,
      },
    ],
  },
  {
    makerId: MAKER_ID.HONDA,
    fuelDetails: [
      {
        modelId: '10008',
        modelName: 'フィット',
        consumption: 38.6,
      },
      {
        modelId: '10112',
        modelName: 'インサイト',
        consumption: 34.2,
      },
      {
        modelId: '10076',
        modelName: 'シャトル',
        consumption: 33.2,
      },
      {
        modelId: '10026',
        modelName: 'ヴェゼル',
        consumption: 30.4,
      },
      {
        modelId: '10046',
        modelName: 'Ｎ－ＷＧＮ',
        consumption: 29,
      },
      {
        modelId: '',
        modelName: 'Ｎ－ＷＧＮカスタム',
        consumption: 29,
      },
      {
        modelId: '10005',
        modelName: 'フリード',
        consumption: 28,
      },
      {
        modelId: '10028',
        modelName: 'フリード+',
        consumption: 28,
      },
      {
        modelId: '10013',
        modelName: 'NBOX',
        consumption: 27,
      },
      {
        modelId: '',
        modelName: 'NBOXカスタム',
        consumption: 27,
      },
      {
        modelId: '10081',
        modelName: 'オデッセイ',
        consumption: 25.2,
      },
      {
        modelId: '10047',
        modelName: 'ステップワゴン',
        consumption: 25,
      },
    ],
  },
  {
    makerId: MAKER_ID.MAZDA,
    fuelDetails: [
      {
        modelId: '10088',
        modelName: 'キャロル',
        consumption: 32,
      },
      {
        modelId: '10123',
        modelName: 'フレア',
        consumption: 31,
      },
      {
        modelId: '10138',
        modelName: 'フレアクロスオーバー',
        consumption: 30.4,
      },
      {
        modelId: '10136',
        modelName: 'フレアワゴン',
        consumption: 30,
      },
      {
        modelId: '10159',
        modelName: 'MAZDA 2',
        consumption: 21.8,
      },
      {
        modelId: '10157',
        modelName: 'MAZDA 3',
        consumption: 18.1,
      },
      {
        modelId: '10167',
        modelName: 'CX-30',
        consumption: 18.1,
      },
      {
        modelId: '10198',
        modelName: 'ＭＸ－３０',
        consumption: 16.9,
      },
      {
        modelId: '10160',
        modelName: 'MAZDA 6',
        consumption: 16.6,
      },
      {
        modelId: '10025',
        modelName: 'ＣＸ－５',
        consumption: 15.6,
      },
    ],
  },
  {
    makerId: MAKER_ID.SUBARU,
    fuelDetails: [
      {
        modelId: '10141',
        modelName: 'プレオ プラス ',
        consumption: 35.2,
      },
      {
        modelId: '10131',
        modelName: 'シフォン',
        consumption: 27.2,
      },
      {
        modelId: '10097',
        modelName: 'ステラ',
        consumption: 26.4,
      },
      {
        modelId: '10019',
        modelName: 'XV',
        consumption: 19.2,
      },
      {
        modelId: '10021',
        modelName: 'インプレッサ',
        consumption: 19.2,
      },
      {
        modelId: '10067',
        modelName: 'フォレスター',
        consumption: 18.6,
      },
      {
        modelId: '10021',
        modelName: 'インプレッサスポーツ',
        consumption: 17.2,
      },
      {
        modelId: '10069',
        modelName: 'レヴォーグ',
        consumption: 16.6,
      },
      {
        modelId: '10106',
        modelName: 'レガシィ アウトバック',
        consumption: 14.3,
      },
      {
        modelId: '10091',
        modelName: 'WRX',
        consumption: 11.4,
      },
    ],
  },
  {
    makerId: MAKER_ID.SUZUKI,
    fuelDetails: [
      {
        modelId: '10053',
        modelName: 'アルト',
        consumption: 32,
      },
      {
        modelId: '10016',
        modelName: 'ワゴンR',
        consumption: 31,
      },
      {
        modelId: '10030',
        modelName: 'ハスラー',
        consumption: 30.4,
      },
      {
        modelId: '10034',
        modelName: 'アルトラパン',
        consumption: 30.2,
      },
      {
        modelId: '10031',
        modelName: 'スペーシア',
        consumption: 30,
      },
      {
        modelId: '10195',
        modelName: 'ワゴンRスマイル',
        consumption: 29.2,
      },
      {
        modelId: '10035',
        modelName: 'スイフト',
        consumption: 28.6,
      },
      {
        modelId: '10060',
        modelName: 'クロスビー',
        consumption: 27.1,
      },
      {
        modelId: '10065',
        modelName: 'ソリオ',
        consumption: 22.4,
      },
      {
        modelId: '10162',
        modelName: 'ランディ',
        consumption: 15.4,
      },
      {
        modelId: '10059',
        modelName: 'ジムニー',
        consumption: 14.6,
      },
      {
        modelId: '10085',
        modelName: 'ジムニーシエラ',
        consumption: 14,
      },
    ],
  },
  {
    makerId: MAKER_ID.DAIHATSU,
    fuelDetails: [
      {
        modelId: '10017',
        modelName: 'ミライース',
        consumption: 35.2,
      },
      {
        modelId: '10169',
        modelName: 'ロッキー',
        consumption: 34.8,
      },
      {
        modelId: '10080',
        modelName: 'ミラトコット',
        consumption: 29.8,
      },
      {
        modelId: '10023',
        modelName: 'ムーヴ キャンバス',
        consumption: 28.6,
      },
      {
        modelId: '10139',
        modelName: 'アルティス',
        consumption: 28.4,
      },
      {
        modelId: '10037',
        modelName: 'ブーン',
        consumption: 28,
      },
      {
        modelId: '10014',
        modelName: 'タント',
        consumption: 27.2,
      },
      {
        modelId: '10024',
        modelName: 'ムーヴ',
        consumption: 26.4,
      },
      {
        modelId: '10176',
        modelName: 'タフト',
        consumption: 25.7,
      },
      {
        modelId: '10078',
        modelName: 'キャスト',
        consumption: 25,
      },
    ],
  },
  {
    makerId: MAKER_ID.MITSUBISHI,
    fuelDetails: [
      {
        modelId: '10118',
        modelName: 'eKクロス(5AA-B34W)',
        consumption: 29.8,
      },
      {
        modelId: '10049',
        modelName: 'eKワゴン(5BA-B33W)',
        consumption: 29.4,
      },
      {
        modelId: '10175',
        modelName: 'eKクロススペース(5AA-B34A)',
        consumption: 27.2,
      },
      {
        modelId: '10152',
        modelName: 'eKスペース(5AA-B34A)',
        consumption: 25.2,
      },
      {
        modelId: '10147',
        modelName: 'デリカＤ：２',
        consumption: 22.4,
      },
      {
        modelId: '10086',
        modelName: 'エクリプスクロス',
        consumption: 15,
      },
    ],
  },
];

/* eslint-disable-next-line arrow-body-style */
export const findByMakerId = (makerId: MAKER_ID): FuelRankingListItem | undefined => {
  return FUEL_LIST.find((item) => item.makerId === makerId);
};

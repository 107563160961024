// extracted by mini-css-extract-plugin
export const cardBox = "CarModelRankingPanel-module--card-box--1v9Ha";
export const cardBoxFigureContainer = "CarModelRankingPanel-module--card-box-figure-container--3C6pV";
export const image = "CarModelRankingPanel-module--image--20tQp";
export const hasTextGrey = "CarModelRankingPanel-module--has-text-grey--3vydO";
export const isMarginAdjust = "CarModelRankingPanel-module--is-margin-adjust--oHf3E";
export const listArrow = "CarModelRankingPanel-module--list-arrow--3XorA";
export const rankingNo1 = "CarModelRankingPanel-module--ranking-no1--2Ad8U";
export const rankingNo2 = "CarModelRankingPanel-module--ranking-no2--2xE4w";
export const rankingNo3 = "CarModelRankingPanel-module--ranking-no3--2pGbs";
export const rankingBasic = "CarModelRankingPanel-module--ranking-basic--3vcT_";
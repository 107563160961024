// extracted by mini-css-extract-plugin
export const carModelPanel = "CarModelRankingPanelPC-module--car-model-panel--35O2y";
export const image = "CarModelRankingPanelPC-module--image--2iQr7";
export const cardBox = "CarModelRankingPanelPC-module--card-box--ewcKN";
export const cardBoxFigureContainer = "CarModelRankingPanelPC-module--card-box-figure-container--3H_kE";
export const isMarginAdjust = "CarModelRankingPanelPC-module--is-margin-adjust--1a8MN";
export const listArrow = "CarModelRankingPanelPC-module--list-arrow--3Iv2c";
export const hasTextGrey = "CarModelRankingPanelPC-module--has-text-grey--SaMYp";
export const car_img = "CarModelRankingPanelPC-module--car_img--2udbU";
export const rankingNo1 = "CarModelRankingPanelPC-module--ranking-no1--3shsj";
export const rankingNo2 = "CarModelRankingPanelPC-module--ranking-no2--1XxHf";
export const rankingNo3 = "CarModelRankingPanelPC-module--ranking-no3--qg-VG";
export const rankingBasic = "CarModelRankingPanelPC-module--ranking-basic--2b6zh";
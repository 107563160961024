import React from 'react';
import { Link } from 'gatsby';

import { Digest } from '@/newcar/util/const/digest';
import Color from '@/newcar/ui/lineup/molecule/Color';

import * as styles from './CarModelRankingPanelPC.module.styl';

type CarModelRankingPanelPCProps = {
  rankNumber: number,
  modelId: string,
  makerName: string,
  modelName: string,
  fuel: { name: string, value: string },
  price: string,
  photo: string,
  bodyColors: Digest['bodyColors'],
}

const CarModelRankingPanelPC = (props: CarModelRankingPanelPCProps): JSX.Element => {
  const {
    rankNumber, modelId, makerName, modelName, fuel, price, photo, bodyColors,
  } = props;

  let rankingStyle = '';
  switch (rankNumber) {
    case 1:
      rankingStyle = styles.rankingNo1;
      break;
    case 2:
      rankingStyle = styles.rankingNo2;
      break;
    case 3:
      rankingStyle = styles.rankingNo3;
      break;
    default:
      rankingStyle = styles.rankingBasic;
      break;
  }

  return (
    <div className={`card ${styles.carModelPanel} is-hidden-mobile`} key={`ranking-panel-pc-${modelId}`}>
      <Link to={`/detail/${modelId}`}>
        <div className="column card-content is-padding-1">
          <p className={`${styles.hasTextGrey} is-size-7 has-text-weight-semibold`}>{makerName}</p>
          <h3 className={`${styles.isMarginAdjust} is-margin-top-1 is-padding-right-3`}><strong className="is-size-4">{modelName}</strong></h3>
          <p className="is-margin-top-1">
            <span className="is-size-3 has-text-primary has-text-weight-bold is-margin-right-1">{fuel.value}</span>
            <span className="is-size-6 has-text-grey-light">{`km/L(${fuel.name})`}</span>
          </p>
          <p className="is-margin-top-3">
            <span className="is-size-3 has-text-weight-semibold is-margin-right-1">{price}</span>
            <span className="is-size-6 has-text-grey-light">円〜/月</span>
          </p>
          {/* 画像 */}
          <div className="column card-image is-padding-1 ">
            <figure className={`column ${styles.image}`}>
              <img src={photo} alt="" />
            </figure>
          </div>
          {/* カラー */}
          <dl className="columns has-text-grey-light is-size-7 is-padding-2">
            <dt className="column is-narrow is-inline-block is-margin-right-1 is-target-dt">カラー</dt>
            <dd className="column is-narrow is-inline-block is-target-dd">
              <Color bodyColors={bodyColors} />
            </dd>
          </dl>
        </div>
        {/* パターン2,3用のarrow */}
        <div className={rankingStyle}><span>{rankNumber}</span></div>
      </Link>
    </div>
  );
};

export default CarModelRankingPanelPC;
